<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          <p>{{ $t("addcompany") }}</p>
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="mainWarehouseCode"
            class="required form-label col-sm-10"
            >{{ $t("companycode") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.company_code"
              type="text"
              class="form-control"
              id="mainWarehouseCode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center">
          <label
            for="mainWarehouseName"
            class="required form-label col-sm-10"
            >{{ $t("initials") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.short_name"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมุล</div>
          </div>
        </div> -->
        <div class="row d-flex justify-content-center">
          <label
            for="mainWarehouseName"
            class="required form-label col-sm-10"
            >{{ $t("companyname") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.company_name"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              <th>{{ $t("save") }}</th>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              <th>{{ $t("cancel") }}</th>
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import userApi from "@/api/user/";

export default {
  setup() {
    document.title = "ERP | เพิ่มบริษัท";
  },
  data: () => ({
    loading: false,
    form: {
      company_code: "",
      company_name: "",
      company_branch: "",
      company_logo: "",
    },
    companyItems: [],
  }),
  created() {
    // this.getAllCompany();
  },
  methods: {
    // async getAllCompany() {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await userApi.company.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.companyItems = responseData.data;
    //     this.form.companyId = responseData.data[0].id;
    //   }
    //   this.loading = false;
    // },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
        };

        createResponse = await whApi.company.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `${this.$t("addsuccess")} `,
            showConfirmButton: false,
            timer: 2300,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/COMPANY",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.company_name
        ? (this.isValid = false)
        : !this.form.company_code
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/COMPANY",
        query: {},
      });
    },
  },
};
</script>
